import React from 'react'  
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HomeList from '../components/homeList'
import GigCard from '../components/card'
import { CardColumns, Badge } from 'reactstrap'
import Link from 'gatsby-link'

const IndexPage = ({ data }) => {
	const gigs = data.allStrapiGigs.edges;
	const artists = data.allStrapiArtists.edges;
	const venues = data.allStrapiVenues.edges;
	const tags = data.allStrapiTags.edges;

	return (  
      <Layout>
      <p>I made this website to organise my bootlegs.</p>
      <p>I'm also learning how to use <a href="https://reactjs.org/">React</a> and <a href="http://gatsbyjs.org/">Gatsby</a>. Everything is confusing!</p>

      <CardColumns>
        {gigs.map((item , i) => (
              <GigCard key={i} poster={item.node.poster} title={item.node.title} date={item.node.date} slug={item.node.slug} description={item.node.description} venue={item.node.venue} />
        ))}
      </CardColumns>
      <h2>Artists</h2>
      {artists.map((item , i) => (
              <Badge className="pill" key={i} tag={Link} to={`/artist/${item.node.slug}`} color="secondary">{item.node.name}</Badge>
        ))}
      <h2>Venues</h2>
      {venues.map((item , i) => (
              <Badge className="pill" key={i} tag={Link} to={`/venue/${item.node.slug}`} color="secondary">{item.node.name}</Badge>
        ))}
      <h2>Tags</h2>
      {tags.map((item , i) => (
              <Badge className="pill" key={i} tag={Link} to={`/tag/${item.node.slug}`} color="secondary">{item.node.name}</Badge>
        ))}
      </Layout>
      ) }

    export default IndexPage

    export const pageQuery = graphql`  
    query IndexQuery {

        allStrapiGigs(sort: {fields: [date], order: DESC}, ) {
          edges {
            node {
              id
              slug
              title
              description
              date(formatString: "DD MMMM YYYY")
              poster {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              venue {
                slug
                name
              }
          }
      }
  }
  allStrapiArtists(sort: {fields: [name], order: ASC}, ) {
      edges {
        node {
          id
          slug
          name
          createdAt
      }
  }
}
allStrapiVenues(sort: {fields: [name], order: ASC}, ) {
  edges {
    node {
      id
      slug
      name
      createdAt
  }
}
}
allStrapiTags(sort: {fields: [name], order: ASC}, ) {
  edges {
    node {
      id
      slug
      name
      createdAt
  }
}
}
}
`