import React from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'

export default class HomeList extends React.Component {
  render() {
    const propItems = this.props.items;
    const type = this.props.type;
    const node = this.props.node;
    const notSlug = this.props.not;
    var items = [];

    // If item is the 'not', remove it from the props list
    if (notSlug) {
      const notIndex = propItems.map(function(e) { return e.slug; }).indexOf(notSlug);
      propItems.splice(notIndex, 1);
    }
    
    // If empty array, say it's empty
    if (propItems.length < 1) {
      return ( <React.Fragment>No {type}s found</React.Fragment> );
    }

    if (node) {
      for (var i = 0; i < propItems.length; i++) {
        items.push(propItems[i].node);
      }
    } else {
      items = propItems;
    }

    if (type === 'gig') {
    return (
        <React.Fragment>
         {items.map((item , i) => (
            <React.Fragment key={i}>
              <Link to={`/${type}/${item.slug}`}><Img fixed={item.poster.childImageSharp.fixed} /><br />
              {item.title}</Link><br />
              {item.date}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
  } else {
    return (
        <ul>
          {items.map((item, i) => (
            <li key={i}><Link to={`/${type}/${item.slug}/`}>{item.name}</Link></li>
          ))}
        </ul>
      );
  }
  }
}