import React from 'react';
import { Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button, Badge } from 'reactstrap'
import Img from 'gatsby-image'
import Link from 'gatsby-link'


const GigCard = (props) => {
  let truncatedDescription = props.description;
  if ((props.description.length) > 120) { truncatedDescription = props.description.substring(0,117) + "..." }
  return (
    <div>
    
      <Card>
        <Link to={`/gig/${props.slug}`}><CardImg tag={Img} top width="100%" fluid={props.poster.childImageSharp.fluid} alt="" /></Link>
        <CardBody>
          <CardTitle><Link to={`/gig/${props.slug}`}>{props.title}</Link></CardTitle>
          <CardSubtitle>{props.date}</CardSubtitle>
          <CardText>{truncatedDescription}</CardText>
          <Badge className="pill" tag={Link} to={`/venue/${props.venue.slug}`} color="secondary">{props.venue.name}</Badge>
        </CardBody>
      </Card>
      
    </div>
  );
};

export default GigCard;